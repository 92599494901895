.custom-soft-steam {
  background: url("../../../../../public/images/soft-background.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 630px;
  margin-top: -20px;
  z-index: 0;
  position: relative;
  margin-bottom: 70px;
  

  @media screen and (max-width: 1250px) {
    background: none;
    height: auto;
  }

  .gradient {
    position: absolute;
    width: 506px;
    height: 506px;
    background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
    filter: blur(347.5px);
    left: 940px;
    top: 10px;
    z-index: 1;

    @media screen and (max-width: 1250px) {
      display: none;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 768px) {
      padding: 0px 25px;
    }

    .left {
      max-width: 60%;
      padding-top: 250px;

      @media screen and (max-width: 1250px) {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 70px;
      }

      h1 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        color: #ffffff;

        @media screen and (max-width: 1250px) {
          font-size: 24px;
          line-height: 30px;
          text-align: center;
        }

        @media screen and (max-width: 768px) {
          font-size: 20px;
          line-height: 25px;
          text-align: center;
        }
      }

      .h1_gradient {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        color: #ffffff;
        background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%),
          linear-gradient(0deg, #ffffff, #ffffff);

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width: 1250px) {
          font-size: 24px;
          line-height: 30px;
        }

        @media screen and (max-width: 768px) {
          font-size: 20px;
          line-height: 25px;
        }
      }

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        margin-bottom: 28px;
        margin-top: 12px;
      }

      .work-btn {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 109.5%;
        text-align: center;
        letter-spacing: 0.05em;
        color: #ffffff;

        background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
        border-radius: 42px;
        padding: 12px 36px;
      }

      .work-btn:hover {
        transition: 0.5s;
        color: #ff00e5;
        background: #ffffff;
        cursor: pointer;

        span {
          background: linear-gradient(
            121.85deg,
            #ff00e5 2.04%,
            #7000ff 108.41%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .right {
      padding-top: 150px;

      @media screen and (max-width: 1250px) {
        display: none;
      }
    }
  }
}
