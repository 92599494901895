.restore-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 32px;

  width: 100%;
  min-height: 80vh;
}

.restore-block__title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;

  color: #FFFFFF;

  @media screen and (max-width: 450px) {
    font-size: 24px;
  }
}

.restore-block__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  text-align: center;

  color: #FFFFFF;

  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
}

.restore-block__contacts {
  display: flex;
  flex-direction: row;
  gap: 24px;

  .restore-block__contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 32px 24px 40px;
    gap: 24px;

    background: #321846;
    border-radius: 55.6949px;

    .restore-block__img {
      width: 43.2px;
      height: 36px;
    }

    .restore-block__contact-title {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;

      color: #FFFFFF;

      @media screen and (max-width:450px) {
        display: none;
      }
    }

    .restore-block__contact-button {
      display: flex;
      padding: 11px 29px;
      background: rgba(255, 255, 255, 0.11);
      border-radius: 12.5763px;

      h3 {
        margin: auto;
        font-style: normal;
        font-weight: 400;
        font-size: 13.4746px;
        line-height: 13px;

        color: #FFFFFF;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}


.restore-block__back-button-wrapper {
  margin-bottom: 20px;
  cursor: pointer;
  width: 292px;
  height: 44px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(121.85deg, #FF00E5 2.04%, #7000FF 108.41%);
}

.restore-block__back-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 290px;
  height: 42px;
  border-radius: 15px;
  background-color: #321846;

  h3 {
    letter-spacing: 0.05em;
    margin: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #FFFFFF;
  }
}