.trademanager-about-section {
    margin-top: -25px;
    position: relative;

    background: url("../../../../../public/images/soft-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 1250px) {
        background: none;
    }

    .gradient {
        position: absolute;
        width: 506px;
        height: 506px;
        background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
        filter: blur(347.5px);
        left: 940px;
        top: 10px;
        z-index: 0;

        @media screen and (max-width: 1250px) {
            left: 40vw;
            top: 50vh;
        }
    }

    .container {
        height: 600px;
        width: 100%;
        position: relative;
        z-index: 2;

        padding-top: 150px;
        display: flex;
        justify-content: space-around;

        @media screen and (max-width: 1250px) {
            flex-direction: column;
            height: auto;
            justify-content: space-between;
        }

        @media screen and (max-width: 768px) {
            padding: 0px 25px;
            padding-top: 150px;
        }

        .right {
            margin-top: -150px;
            height: 534px;
            width: 50%;
            background: url("../../../../../public/images/searcher.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;
            transform: scale(-1, 1);

            @media screen and (max-width: 1250px) {
                align-self: center;
                margin-top: 0px;
                background-size: contain;
                width: 100%;
            }


            @media screen and (max-width: 768px) {
                max-height: 320px;
            }

        }

        .left {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            @media screen and (max-width: 1250px) {
                align-items: flex-start;
                margin-bottom: 20px;
                width: 100%;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
            }

            h1 {
                font-family: "Unbounded";
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 10px;

                @media screen and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.05em;

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }
    }
}