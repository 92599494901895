.trademanager-advantages-section {
    position: relative;
    z-index: 3;

    .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 768px) {
            padding: 0px 25px;
        }

        h1 {
            font-family: "Unbounded";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            color: #ffffff;
            margin-bottom: 50px;
        }

        .list {
            width: 100%;
            display: flex;
            margin-bottom: 30px;

            @media screen and (max-width: 1250px) {
                display: none;
            }

            .item {
                width: 555px;
                height: 346px;
                margin-right: 30px;
                display: flex;
                position: relative;

                .background {
                    position: absolute;
                    z-index: 0;
                }

                .text-wrapper {
                    z-index: 1;
                    padding: 30px 30px 30px 270px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    h2 {
                        font-family: "Unbounded";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        color: #ffffff;
                        margin-bottom: 10px;
                    }

                    p {
                        font-family: "Montserrat";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 109.5%;
                        letter-spacing: 0.05em;
                        color: #bb9ccf;
                    }
                }
            }

            .item:last-child {
                margin-right: 0px;
            }
        }

        .list-medium {
            width: 100%;
            display: none;
            margin-bottom: 20px;

            @media screen and (max-width: 1250px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 0px;
            }

            .item {
                max-width: 720px;
                height: 260px;
                margin-bottom: 20px;
                display: flex;
                position: relative;

                @media screen and (max-width: 1250px) {
                    width: auto;
                }

                @media screen and (max-width: 768px) {
                    width: 100%;
                    height: auto;
                    background: #3a1652;
                    border-radius: 38px;
                }

                .background {
                    position: absolute;
                    z-index: 0;

                    @media screen and (max-width: 1250px) {
                        width: 100%;
                    }

                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }

                .text-wrapper {
                    z-index: 1;
                    padding: 30px 30px 30px 270px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media screen and (max-width: 768px) {
                        padding: 36px;
                    }

                    h2 {
                        font-family: "Unbounded";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        color: #ffffff;
                        margin-bottom: 10px;
                    }

                    p {
                        font-family: "Montserrat";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 109.5%;
                        letter-spacing: 0.05em;
                        color: #bb9ccf;
                    }
                }
            }

            .item:last-child {
                margin-right: 0px;
            }
        }
    }
}