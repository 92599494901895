.custom-soft-crypto {
  background: url("../../../../../public/images/soft-background.png");
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 630px;

  @media screen and (max-width: 1250px) {
    background: none;
  }

  .gradient {
    position: absolute;
    width: 506px;
    height: 506px;
    background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
    filter: blur(347.5px);
    left: 940px;
    top: 10px;
    z-index: 0;

    @media screen and (max-width: 1250px) {
      display: none;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    z-index: 2;
    position: relative;

    @media screen and (max-width: 768px) {
        padding: 0px 25px;
        flex-direction: column-reverse;
        align-items: center;
      }

    .left {
      width: 50%;
      background: url("../../../../../public/images/searcher.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 400px;
      background-position: left;
      margin-left: -30px;

      @media screen and (max-width: 1250px) {
        flex: 0 0 400px;
      }

      @media screen and (max-width: 768px) {
        width: 90%;
        background-size: contain;
        background-position: center;
      }

    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 475px;

      h1 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        margin-bottom: 12px;

        @media screen and (max-width: 1250px) {
            font-size: 24px;
            line-height: 30px;
          }

        .gradient-text {
          font-family: "Unbounded";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 40px;
          text-align: center;
          text-transform: uppercase;
          background: linear-gradient(
            121.85deg,
            #ff00e5 2.04%,
            #7000ff 108.41%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;

          @media screen and (max-width: 1250px) {
            font-size: 24px;
            line-height: 30px;
          }
        }
      }

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
      }

    }
  }
}
