.advantages-section {
  position: relative;
  margin-top: 120px;
  
  .container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      padding: 0 25px;
      margin-bottom: 100px;
    }


    .advantages-section__info {
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      min-height: auto;
      width: 100%;
      display: flex;
      margin-bottom: 40px;

      .text {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 28px;
        color: #ffffff;

        @media screen and (max-width: 768px) {
          font-family: "Unbounded";
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 22px;
          color: #ffffff;
        }
      }

      .sliders {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 20px;

        .btn-left {
          width: 41px;
          height: 41px;
          background: url("../../../../../public/images/slider-left.svg");
          background-position: center;
          background-repeat: no-repeat;
        }

        .btn-left:hover {
          background: url("../../../../../public/images/slider-active-left.svg");
          cursor: pointer;
        }

        .btn-right {
          width: 41px;
          height: 41px;
          background: url("../../../../../public/images/slider-right.svg");
          background-position: center;
          background-repeat: no-repeat;
        }

        .btn-right:hover {
          background: url("../../../../../public/images/slider-active-right.svg");
          cursor: pointer;
        }
      }
    }

    .advantages-section__slider {
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;

      .slider__slide {
        border-radius: 28px;
        background: transparent;
        height: 325px;
        display: flex;
        justify-content: center;
        margin: 0px 15px;

        .slider_slide-content {
          width: 262px;
          height: 100%;
          border-radius: 28px;
          background-color: #3a1652;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          padding: 0px 15px 15px 15px;
          background-repeat: no-repeat;

          h1 {
            font-family: "Unbounded";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
            align-self: start;
          }

          span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 109.5%;
            letter-spacing: 0.05em;
            color: #6c4b83;
            margin-top: 8px;
            max-height: 110px;
            overflow: hidden;
            text-overflow: ellipsis;

          }
        }
      }
    }
  }
}
