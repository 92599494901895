.top-panel {
  display: flex;
  justify-content: space-between;
  min-width: 350px;
  width: 100%;
  margin-top: 50px;

  @media screen and (max-width: 860px) {
    flex-direction: column;
  }
}

.top-panel__left {
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.top-panel__balance-block {
  display: flex;
  flex-direction: row;
}

.top-panel__balance {
  background-color: #432059;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  color: #999;
  font-size: 14px;
  height: 55px;
  margin-right: 20px;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.top-panel__balance-value {
  font-size: 16px;
  color: #fff;
  margin-left: 5px;
  font-weight: bold;
  margin-right: 10px;
}

.top-panel__balance-button {
  background-color: red;
  color: #fff;
  height: 29px;
  line-height: 29px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  background: linear-gradient(121.85deg, #FF00E5 2.04%, #7000FF 108.41%);
  cursor: pointer;
}

.top-panel__user-info {
  height: 55px;
  border-radius: 10px;
  background-color: #432059;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  color: #999;
  justify-content: space-between;
}

.top-panel__email {
  margin-left: 10px;
  color: #fff;
}

.top-panel__logout-btn {
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.top-panel__chat-button {
  background: linear-gradient(121.85deg, #FF00E5 2.04%, #7000FF 108.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 14px;
  margin-left: 10px;
  border: 1px solid #EC01E8;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 12px;

  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  cursor: pointer;

  width: 176px;
  height: 55px;

  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;


  @media screen and (max-width: 860px) {
    margin-top: 10px;
    margin-left: 0px;
    justify-content: center;
    width: 100%;
  }
}