.our-mission {
    
  margin-bottom: 50px;

  .container {
    text-align: center;

    @media screen and (max-width: 768px) {
      padding: 0px 25px;
    }

    h1 {
      font-family: "Unbounded";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;

      @media screen and (max-width: 1250px) {
        font-size: 24px;
        line-height: 30px;
      }

      .gradient-text {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        text-transform: uppercase;
        background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media screen and (max-width: 1250px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
}
