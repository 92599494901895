.add-balance__back-img {
    height: 50px;
    width: 50px;
    margin-right: 15px;
    cursor: pointer;
    margin-top: 50px;

    @media screen and (max-width: 860px) {
        display: none;
    }
}

.add-balance__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.add-balance__content {
    min-width: 350px;
    margin-top: 30px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
    }
}

.add-balance__form {
    width: 320px;
    min-width: 320px;

    @media screen and (max-width: 900px) {
        padding-bottom: 20px;
        width: 100%;
    }
}

.add-balance__to-pay-container {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;

}

.add-balance__title {
    font-size: 18px;
    margin-top: 30px;
}

.add-balance__method {
    width: 250px;
    height: 200px;
    border-radius: 10px;
    padding: 24px;
    cursor: pointer;
    border: 1px solid #432059;
}

.add-balance__method--active {
    background-color: #432059;
    border: 1px solid #EC01E8;
}

.add-balance__method:hover {
    background-color: #432059;
    border: 1px solid #EC01E8;
}

.add-balance__methods {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    gap: 10px;

    @media screen and (max-width: 900px) {
        justify-content: center;
    }
}

.add-balance__to-main-button {
    color: #EC01E8;
    text-decoration: underline;
    cursor: pointer;

}

.add-balance__method-text {
    font-size: 10px;
    line-height: 17px;
    color: #999999;
}

.add-balance__method-img {
    width: 50px;
    margin-bottom: 10px;
}