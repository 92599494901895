.help-faq {
  
    width: 100%;
  
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      @media screen and (max-width: 768px) {
        padding: 0 25px;
      }
  
      h1 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        color: #ffffff;
        margin-bottom: 42px;
      }
  
      .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
  
  
      }
    }
  }
  