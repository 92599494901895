.custom-sofr-about-us {
  .container {
    display: flex;

    @media screen and (max-width: 1250px) {
      flex-direction: column;
    }

    @media screen and (max-width: 768px) {
      padding: 0px 25px;
    }

    .left {
      display: flex;
      flex-direction: column;
      margin-right: 18px;

      @media screen and (max-width: 1250px) {
        margin: 0;
      }

      .item {
        background: #432059;
        border-radius: 24px;
        padding: 36px;
        margin-bottom: 18px;
        height: 50%;

        h1 {
          font-family: "Unbounded";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          color: #ffffff;

          @media screen and (max-width: 768px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }

    .right {
      width: 40%;

      @media screen and (max-width: 1250px) {
        width: 100%;
      }

      .item {
        background: #432059;
        border-radius: 24px;
        padding: 28px;
        margin-bottom: 18px;

        .logo {
          width: 168px;
          height: 72px;

          @media screen and (max-width: 1250px) {
          }
        }

        h1 {
          font-family: "Unbounded";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 40px;
          text-transform: uppercase;
          color: #ffffff;
          margin-bottom: 24px;

          @media screen and (max-width: 1250px) {
            font-size: 24px;
            line-height: 30px;
          }

          @media screen and (max-width: 768px) {
            font-size: 18px;
            line-height: 22px;
          }
        }

        .h1_gradient {
          background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%),
            linear-gradient(0deg, #ffffff, #ffffff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
