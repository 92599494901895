.guide-section {
    margin-bottom: 70px;
}

.guide-section__link-wrapper {
    height: 326px;
    width: 555px;
    border-radius: 50px;
    padding: 15px 20px 15px 20px;
    display: flex;
    min-width: 300px;

    @media screen and (max-width: 768px) {
        width: 100%;

    }

    cursor: pointer;
    transition: padding 0.3s;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:hover {
        padding: 0px;

    }
}

.guide-section__link-text-container {
    background: linear-gradient(121.85deg, rgba(255, 0, 229, 0.78) 2.04%, rgba(112, 0, 255, 0.78) 108.41%);
    backdrop-filter: blur(2px);
    padding: 30px;
    width: 100%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.guide-section {
    .container {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        @media screen and (max-width: 1280px) {
            flex-direction: column;
            align-items: center;
        }

        @media screen and (max-width: 768px) {
            padding: 0px 25px;
            flex-direction: column;
            align-items: center;
        }
    }
}