.price-tag__usd {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
}

.price-tag__rub {
    color: #999;
    font-size: 12px;
}

.price-tag {
    display: flex;
    line-height: 25px;
    gap: 5px;
}