.learn-more-section {
  margin-bottom: 100px;
  position: relative;
  background: url(../../../../../public/images/learn-more-background1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    background: none;
  }

  .logo-big {
    position: absolute;
    background: url("../../../../../public/images/Union.png");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    top: -200px;
    z-index: -1;
    opacity: 0.1;
    background-position: top;

    @media screen and (max-width: 768px) {
      background: none;
    }
  }

  .container {
    min-height: 668px;
    padding-top: 140px;
    grid-gap: 90px;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      min-height: auto;
      height: auto;
      padding: 0 25px;
    }

    .right {
      flex: 1 1 auto;
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        justify-content: center;
        height: 100%;
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (max-width: 768px) {
        }

        h1 {
          font-family: "Unbounded";
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 28px;
          color: #ffffff;

          @media screen and (max-width: 768px) {
            max-width: 350px;
          }
        }

        span {
          margin-top: 10px;

          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.05em;
          color: #ffffff;
        }

        .actions__link-btn {
          margin-top: 20px;
          white-space: nowrap;
          padding: 23px 33px;

          span {
            font-family: "Unbounded";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 109.5%;
            letter-spacing: 0.05em;
            color: #ffffff;
          }

          background: linear-gradient(
            121.85deg,
            #ff00e5 2.04%,
            #7000ff 108.41%
          );
          border-radius: 200px;

          @media screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
          }
        }

        .actions__link-btn:hover {
          transition: 0.5s;
          color: #ff00e5;
          background: #ffffff;

          span {
            background: linear-gradient(
              121.85deg,
              #ff00e5 2.04%,
              #7000ff 108.41%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    .left {
      flex: 1 1 auto;
      background: url("../../../../../public/images/mock-up.png");
      background-size: contain;
      background-repeat: no-repeat;
      min-width: 50%;
      width: 529px;
      height: 560px;
      max-height: 560px;

      // .picture {
      //   background: url("../../../../../public/images/learn-more.png");
      //   background-repeat: no-repeat;
      //   background-size: contain;
      //   background-position: center;
      //   width: 550px;
      //   height: 550px;
      //   min-width: 380px;
      //   min-height: 340px;
      //   margin-top: -200px;
      // }

      @media screen and (max-width: 1250px) {
        margin-top: 0px;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
