.intro-section {
    position: relative;
    padding-bottom: 129px;
    display: flex;
    justify-content: center;
}

.intro-section .container {
    max-width: 1363px;
    width: 100%;
    height: 559px;
    min-height: 559px;
    border-radius: 75px;
    padding: 0px 45px;

    background: url("/public/images/intro.png") 50% 50% no-repeat;
    background-size: cover;
}

.intro-section .button {
    position: absolute;
    top: 550px;
}

.intro-section__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 1140px;
}

.intro-section__desc {
    margin-top: 13px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 109.5%;
    letter-spacing: 0.05em;
    color: #ffffff;
    max-width: 350px;
}

.intro-section__content .actions__btn {
    transition: 0.5s;
    background: #ffffff;
    border-radius: 200px;
    padding: 25px;
    margin-top: 25px;
}

.intro-section__content .actions__btn:hover {
    transition: 0.5s;
    background: linear-gradient(121.85deg,
            #ff00e5 2.04%,
            #7000ff 108.41%);
}

.intro-section__content .actions__btn:hover .gradient-text {
    transition: 0.5s;
    background: #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.gradient-text {
    font-family: "Unbounded";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 109.5%;
    letter-spacing: 0.05em;
    background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

@media screen and (max-width: 915px) {
    h1 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 45px;
        color: #ffffff;
    }

    #bobina-1 {
        display: none;
    }

    #bobina-2 {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    h1 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        color: #ffffff;
    }

    .intro-section {
        padding-left: 25px;
        padding-right: 25px;
    }

    .intro-section__content {
        width: 100%;
    }

    .intro-section .container {
        padding: 0 33px;
        background: url("/public/images/intro.png") 50% 50% no-repeat;
    }

    .intro-section__content .actions__btn {
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 490px) {
    h1 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color: #ffffff;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 490px) {
    .intro-section__desc {
        margin-top: 13px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 109.5%;
        letter-spacing: 0.05em;
        color: #ffffff;
        max-width: auto;
    }
}

#bobina-1 {
    position: absolute;
    left: 55%;
    top: 350px;
}

#bobina-2 {
    position: absolute;
    left: 10%;
    top: 450px;
}