.prompt__container {
  position: absolute;
  max-width: 520px;
  max-height: 400px;
  height: 40%;
  background-color: #391C50;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  border-radius: 50px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-size: 20px;
  }

  p {
    color: #999;
    font-size: 16px;
    margin-bottom: 20px;
  }

  img {
    width: 48px;
  }

  button {
    width: 200px;
  }
}

.prompt {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  /* Черный фон с 50% прозрачностью */
  backdrop-filter: blur(10px);
}

.unVisible {
  display: none;
}