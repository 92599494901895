.trademanager-info-section {
    margin-bottom: 40px;

    .container {
        height: auto;
        width: 100%;

        display: flex;
        justify-content: space-around;

        padding-top: 30px;

        @media screen and (max-width: 1250px) {}

        @media screen and (max-width: 768px) {
            padding: 0 25px;
        }

        .left {
            margin-top: -150px;
            margin-left: -35px;
            height: 534px;
            width: 40%;
            background: url("../../../../../public/images/info.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;

            @media screen and (max-width: 1250px) {
                display: none;
            }
        }

        .right {
            width: 75%;
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 1250px) {
                width: 100%;
                justify-content: space-around;
                grid-gap: 25px;
            }

            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
            }

            h1 {
                font-family: "Unbounded";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 24px;
                display: flex;
                align-items: flex-end;
                color: #ffffff;
                margin-bottom: 20px;
            }

            p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.05em;
                color: #ffffff;
                max-width: 360px;

                @media screen and (max-width: 768px) {
                    margin-bottom: 40px;
                }
            }

            .icon-container {
                display: flex;
                height: 80px;
                margin-bottom: 20px;

                .logo {
                    width: 77px;
                    height: 77px;
                }

                .icon-text-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 10px;

                    h2 {
                        font-family: "Unbounded";
                        font-style: normal;
                        font-weight: 900;
                        font-size: 18px;
                        line-height: 18px;
                        color: #ffffff;
                    }

                    p {
                        font-family: "Unbounded";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 14px;
                        color: #d7bff6;
                        margin: 0;


                    }
                }
            }
        }
    }
}