.contact-with-us {
  margin-bottom: 35px;

  .container {
    @media screen and (max-width: 768px) {
      padding: 0px 25px;
    }

    .wrapper {
      padding: 56px;
      background: #432059;
      border-radius: 24px;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        padding: 24px;
      }

      .left {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: start;

        @media screen and (max-width: 768px) {
          margin-bottom: 24px;
          align-items: center;
          width: 100%;
        }

        h1 {
          font-family: "Unbounded";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 40px;
          text-transform: uppercase;
          color: #ffffff;

          @media screen and (max-width: 1250px) {
            font-size: 24px;
            line-height: 30px;
          }

          @media screen and (max-width: 768px) {
            font-size: 20px;
            line-height: 25px;
          }
        }

        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: #ffffff;
          margin-bottom: 28px;
          margin-top: 12px;
        }

        .work-btn {
          font-family: "Unbounded";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 109.5%;
          text-align: center;
          letter-spacing: 0.05em;
          color: #ffffff;
          max-width: 332px;

          background: linear-gradient(
            121.85deg,
            #ff00e5 2.04%,
            #7000ff 108.41%
          );
          border-radius: 42px;
          padding: 12px 36px;
        }
        
        .work-btn:hover {
          transition: 0.5s;
          color: #ff00e5;
          background: #ffffff;
          cursor: pointer;

          span {
            background: linear-gradient(
              121.85deg,
              #ff00e5 2.04%,
              #7000ff 108.41%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .right {
        background: url("../../../../../public/images/links.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 316px;
        height: 316px;

        @media screen and (max-width: 1250px) {
          width: 190px;
          height: 190px;
        }
      }
    }
  }
}
