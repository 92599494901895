.help {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h1 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 600;
        font-size: 42px;
        line-height: 52px;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 12px;

        @media screen and (max-width: 1250px) {
            display: none;
        }
    }

    h2 {
        display: none;
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        color: #ffffff;
        text-align: center;
        margin-bottom: 25px;

        @media screen and (max-width: 1250px) {
            display: flex;
            padding: 0px 65px;
        }

        @media screen and (max-width: 768px) {
            display: flex;
            padding: 0px 25px;
        }
    }

    p {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        margin-bottom: 70px;

        @media screen and (max-width: 1250px) {
            display: none;
        }
    }
}