.add-balance-loading__img {
    width: 250px;
}

.add-balance-loading .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.add-balance-loading {
    margin-top: 100px;
}