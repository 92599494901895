html {
  height: 100%;
}

body {
  color: #fff;
  font-family: "Unbounded";
  background: #260f38;
  overflow-x: hidden;
  min-height: 100vh;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
}

.container {
  max-width: 1270px;
  padding: 0 65px;
  margin: 0 auto;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

input:focus {
  outline: none;
}

p {
  margin: 0;
}

h1 {
  font-family: "Unbounded";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 55px;
  color: #ffffff;
  margin: 0;
}

h2, h3, h4, h5, h6 {
  margin: 0;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
}

@font-face {
  font-family: "Unbounded";
  src: local("Unbounded"),
  url("./fonts/Unbounded/Unbounded-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
  url("./fonts/Montserrat/montserrat_regular.woff2") format("truetype");
}


.basic-button {
  width: 100%;
  font-family: Unbounded;
  font-size: 14px;
  text-align: center;
  border: 0px;

  min-height: 44px;

  border-radius: 42px;
  height: 44px;
  line-height: 44px;

  cursor: pointer;
}

.basic-button--gradient {
  color: #fff;
  background: linear-gradient(121.85deg, #FF00E5 2.04%, #7000FF 108.41%);
}

.basic-button--gradient:hover {
}


.basic-input {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #815e9c;
  background: #482664;
  border: 1px solid #5d347d;
  border-radius: 12px;
  padding: 18px 25px;
  height: 50px;
  width: 100%;
}

.wrong-input {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #815e9c;
  background: #65328f;
  border: 1px solid #cb1536;
  box-shadow: 0 0 5px #FF0000, 0 0 10px #FF0000, 0 0 15px #FF0000;
  border-radius: 12px;
  padding: 18px 25px;
  height: 50px;
  width: 100%;
}

.input-form_text {
  color: #fff;
  font-family: "Unbounded";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

.input-form {
  padding-top: 10px;
  padding-bottom: 10px;
}

.big-grey-text {
  font-size: 20px;
  color: #999;
  text-transform: none;
  text-transform: inherit;
}


.light-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.light-indicator--green {
  background-color: #00FF00;
  box-shadow: 0 0 10px #00FF00, 0 0 20px #00FF00, 0 0 30px #00FF00;
}

.light-indicator--red {
  background-color: #FF0000;
  box-shadow: 0 0 10px #FF0000, 0 0 20px #FF0000, 0 0 30px #FF0000;
}

.light-indicator--yellow {
  background-color: #FFFF00;
  box-shadow: 0 0 10px #FFFF00, 0 0 20px #FFFF00, 0 0 30px #FFFF00;
}

.light-indicator--black {
  background-color: #999;
  box-shadow: 0 0 1px #999, 0 0 2px #999, 0 0 3px #999;
}