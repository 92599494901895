.seller-intro-section {
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1250px) {
        padding-top: 50px;
    }

    .container {
        display: flex;

        @media screen and (max-width: 1250px) {
            display: flex;
            justify-content: space-between;
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            padding: 0px 25px;
        }

        .left {
            width: 50%;
            margin-right: 48px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
                margin-bottom: 20px;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-right: 0px;
            }

            h1 {
                font-family: "Unbounded";
                font-style: normal;
                font-weight: 600;
                font-size: 42px;
                line-height: 52px;
                text-transform: uppercase;
                color: #ffffff;
                margin-bottom: 27px;

                @media screen and (max-width: 1250px) {
                    font-size: 24px;
                    line-height: 30px;
                }
            }

            p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #7c5795;
                margin-bottom: 27px;

                @media screen and (max-width: 1250px) {
                    font-size: 14px;
                }
            }

            .list {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 32px;

                @media screen and (max-width: 1250px) {
                    flex-direction: column;
                    margin-bottom: 0px;
                }

                @media screen and (max-width: 768px) {
                    width: 100%;
                }



                .item {
                    background: #3a1652;
                    border-radius: 31px;
                    padding: 10px;
                    max-width: 165px;
                    height: 140px;

                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    @media screen and (max-width: 1250px) {
                        width: 100%;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        height: 48px;
                        max-width: 1000px;
                        margin-bottom: 12px;
                    }

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }

                    .icon {
                        width: 24px;
                        height: 24px;
                        margin-bottom: 13px;

                        @media screen and (max-width: 1250px) {
                            margin-bottom: 0px;
                            margin-right: 13px;
                        }
                    }

                    h1 {
                        font-family: "Montserrat";
                        font-style: normal;
                        text-transform: none;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 109.5%;
                        letter-spacing: 0.05em;
                        color: #ffffff;
                        margin: 0;

                        @media screen and (max-width: 1250px) {
                            font-size: 12px;
                        }
                    }
                }
            }

            .price {
                display: flex;
                align-items: center;

                @media screen and (max-width: 1250px) {
                    display: none;
                }

                h1 {
                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 30px;
                    color: #ffffff;
                    margin: 0;
                    margin-right: 10px;
                    white-space: nowrap;

                    @media screen and (max-width: 1250px) {
                        font-size: 19px;
                        line-height: 19px;
                    }
                }

                p {
                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #6c4b83;
                    margin: 0;
                    margin-right: 30px;

                    @media screen and (max-width: 1250px) {
                        font-size: 11px;
                        line-height: 11px;
                    }
                }

                .buy-btn {

                    a {
                        color: #fff;
                    }

                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 109.5%;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: #ffffff;

                    height: 44px;
                    padding: 12px 0px;
                    width: 300px;

                    background: linear-gradient(121.85deg,
                        #ff00e5 2.04%,
                        #7000ff 108.41%);
                    border-radius: 42px;

                    @media screen and (max-width: 1250px) {
                        font-size: 14px;
                        line-height: 14px;
                    }
                }

                .buy-btn:hover {
                    transition: 0.5s;
                    color: #ff00e5;
                    background: #ffffff;
                    cursor: pointer;

                    span {

                        background: linear-gradient(121.85deg,
                                #ff00e5 2.04%,
                                #7000ff 108.41%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 50%;

            @media screen and (max-width: 768px) {
                height: 370px;
                width: 100%;
                justify-content: center;
            }

            @media screen and (max-width: 1250px) {
                margin-top: 0px;
                width: 100%;
                align-items: center;
            }

            .image {
                border-radius: 30px;
                background: url("../../../../../public/images/tmseller/1.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 100%;
                border: 1px solid #481C60;

                @media screen and (max-width: 1250px) {
                    margin-bottom: 10px;
                }

                @media screen and (max-width: 768px) {
                    height: 80%;
                }
            }

            .price {
                width: 100%;
                display: none;
                align-items: center;

                @media screen and (max-width: 1250px) {
                    display: flex;
                    align-self: center;
                }

                h1 {
                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 30px;
                    color: #ffffff;
                    margin: 0;
                    margin-right: 10px;
                    white-space: nowrap;

                    @media screen and (max-width: 1250px) {
                        font-size: 19px;
                        line-height: 19px;
                    }
                }

                p {
                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #6c4b83;
                    margin: 0;
                    margin-right: 30px;

                    @media screen and (max-width: 1250px) {
                        font-size: 11px;
                        line-height: 11px;
                    }
                }

                .buy-btn {

                    a {
                        color: #fff;
                    }

                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 109.5%;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: #ffffff;

                    padding: 12px 0px;
                    width: 300px;

                    background: linear-gradient(121.85deg,
                        #ff00e5 2.04%,
                        #7000ff 108.41%);
                    border-radius: 42px;

                    @media screen and (max-width: 1250px) {
                        padding: 12px 15px;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }

                .buy-btn:hover {
                    transition: 0.5s;
                    color: #ff00e5;
                    background: #ffffff;
                    cursor: pointer;

                    span {
                        a {
                            color: #fff;
                        }

                        background: linear-gradient(121.85deg,
                            #ff00e5 2.04%,
                            #7000ff 108.41%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
}