.modal-window__container {
    position: absolute;
    min-width: 400px;
    min-height: 300px;
    max-width: 520px;
    max-height: 400px;
    background-color: #391C50;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 50px;
    padding: 40px;
    overflow: auto;
}

.modal-window {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    /* Черный фон с 50% прозрачностью */
    backdrop-filter: blur(10px);
}