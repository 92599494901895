.tmseller-bots {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.tmseller-content {
  overflow-y: scroll;
}

.tmseller-bots__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 14px;
}

.tmseller-bots__subscribe-header {
  position: relative;
  left: -55px;
}

.tmseller-bots__content {
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  background: #391C50;
  border-radius: 0px 0px 10px 10px;
  overflow-y: auto;
  height: 100%;
  padding-right: 10px;
  margin-top: 10px;
  max-height: 560px;
}


.tmseller-bots__card-bot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  //height: 68px;
  width: 100%;
  background: linear-gradient(91.21deg, #321846 0%, #561370 100%);
  border-radius: 6px;


  @media screen and (max-width: 680px) {
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
  }
}

.tmseller-bots__name-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 30px;
}

.tmseller-bots__name-number {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  min-width: 20px;
  color: #999999;
}

.tmseller-bots__name {
  margin: 0 0 0 10px;
  max-width: 150px;
  width: 130px;
  overflow: hidden;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: #FFFFFF;
}

.tmseller-bots__prolong-button-wrapper {
  cursor: pointer;
  width: 110px;
  height: 29px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(121.85deg, #FF00E5 2.04%, #7000FF 108.41%);
}

.tmseller-bots__prolong-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 108px;
  height: 27px;
  border-radius: 40px;
  background-color: #561370;

  h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.05em;
    background: linear-gradient(121.85deg, #FF00E5 2.04%, #7000FF 108.41%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    margin: auto;
  }
}

.tmseller-bots__prolong-button:hover {
  background: rgba(0, 0, 0, 0);

  h3 {
    -webkit-text-fill-color: #ffffff;
  }
}


.tmseller-bots__subscribe-block {
  display: flex;

  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 143px;
  height: 100%;

  h3,
  h4 {
    margin: 0;
  }
}

.tmseller-bots__subscribe {
  width: 98px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #A6A6A6;
}

.tmseller-bots__subscribe-date {
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #FFFFFF;
}

.link-screen {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 15px;
}

.tmseller-add-bot__form {
  width: 50%;
  //min-height: 100%;
  height: 100%;
  max-height: 400px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.tmseller-add-bot {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
  flex-direction: row;
  text-align: center;
  gap: 20px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;

    .tmseller-add-bot__form {
      width: 100%;
    }

    .tmseller-add-bot__faq {
      width: 100%;
      text-align: center;
      height: 100%;
    }

    .tmseller-add-bot__div {
      display: none;
    }
  }
}

.tmseller-add-bot__input-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.tmseller__none-balance-button {
  color: #fff;
  background: #999999;
}

.tmseller__error-balance-button {
  background: #e75050;
}

.tmseller-add-bot__title {
  font-size: 18px;
  margin-bottom: 10px;
}

.tmseller-add-bot__input-container {
  margin-top: 20px;
}

.tmseller-add-bot__input-title {
  font-size: 14px;
}

.tmseller-add-bot__sum {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tmseller-add-bot__faq {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tmseller-add-bot__title {
  font-size: 18px;
}

.tmseller-add-bot__questions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tmseller-add-bot__question {
  color: #8130B5;
  cursor: pointer;

  text-decoration: underline;
}

.tmseller-add-bot__div {
  width: 0;
  height: 100%;
  border: 1px solid #5D347D;
}

.tmseller-add-bot__input {
  border-radius: 15px;
  background-color: #321846;
  display: flex;
  justify-content: space-between;
  height: 41px;
  gap: 10px;

  input {
    color: #fff;
    width: 100%;
    background-color: transparent;
    border: 0;
    padding-left: 20px;
    font-size: 14px;
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    border-radius: 15px;
    background-color: transparent;
  }
}

.tmseller-add-bot__wrong-input {
  border-radius: 15px;
  background-color: #321846;
  display: flex;
  justify-content: space-between;
  height: 41px;
  gap: 10px;
  border: 1px solid #cb1536;
  box-shadow: 0 0 5px #FF0000, 0 0 10px #FF0000, 0 0 15px #FF0000;

  input {
    color: #fff;
    width: 100%;
    background-color: transparent;
    border: 0;
    padding-left: 20px;
    font-size: 14px;
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    border-radius: 15px;
    background-color: transparent;
  }
}

.tmseller-add-bot__input-text {
  font-size: 12px;
  color: #999;
  line-height: 41px;
  padding-right: 15px;
}

.content-screen__refresh-btn {
  cursor: pointer;
}

.user-account {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.user-account__top-panel {
  display: flex;
  justify-content: space-between;
  min-width: 350px;
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.user-account__top-panel-left {
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.user-account__balance {
  background-color: #432059;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  color: #999;
  font-size: 14px;
  height: 55px;
  margin-right: 20px;

  @media screen and (max-width: 900px) {
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.user-account__balance-number {
  font-size: 16px;
  color: #fff;
  margin-left: 5px;
  font-weight: bold;
  margin-right: 10px;
}

.user-account__balance-button {
  background-color: red;
  color: #fff;
  height: 29px;
  line-height: 29px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  background: linear-gradient(121.85deg, #FF00E5 2.04%, #7000FF 108.41%);
  cursor: pointer;
}

.user-account__user-info {
  height: 55px;
  border-radius: 10px;
  background-color: #432059;
}

.user-account__user-info {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  color: #999;
}

.user-account__email {
  margin-left: 10px;
  color: #fff;
}

.user-account__logout-btn {
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.user-account__chat-button {
  background: linear-gradient(121.85deg, #FF00E5 2.04%, #7000FF 108.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //background-clip: text;
  text-fill-color: transparent;
  font-size: 14px;
  border: 1px solid #EC01E8;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 12px;

  //font-family: 'Unbounded';
  font-style: normal;
  font-weight: 400;
  //font-size: 12px;
  line-height: 17px;
  cursor: pointer;

  width: 176px;
  height: 55px;

  border-radius: 10px;
  order: 1;
  flex-grow: 0;


  @media screen and (max-width: 900px) {
    margin-top: 10px;
    margin-left: 0px;
  }
}

.user-account__content {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  height: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.user-account__left-content {
  margin-right: 20px;
  border-radius: 16px;
  padding: 16px;
  gap: 8px;
  width: 229px;
  background-color: #432059;
  height: fit-content;

  @media screen and (max-width: 1024px) {
    width: 100%;
    min-width: 350px;
    margin-right: 0px;
  }
}

.user-account__left-content-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
}

.user-account__app-title {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 8px;
  gap: 6px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.user-account__app-title--active {
  background-color: #FF00E5;
}

.user-account__app-title:hover {
  background-color: #79409C;
}

.user-account__content-buttons {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 630px) {
    flex-direction: column;

    border-radius: 10px;
  }
}

.user-account__content-button {
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  font-size: 14px;
  width: 100%;

  @media screen and (max-width: 1150px) {
    font-size: 10px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.user-account__content-button_active {
  border-radius: 10px 10px 0 0;
  background-color: #432059;
}

.user-account__content-button:hover {
  background: rgba(67, 32, 89, 0.37);
  cursor: pointer;
}

@media screen and (max-width: 630px) {
  .user-account__content-button {
    background-color: #432059;
    border-radius: 0;
  }

  .tmseller-bots__subscribe-header {
    display: none;
  }
  .user-account__content-button_active {
    border-radius: 0;
  }

  .user-account__content-button:first-child {
    border-radius: 10px 10px 0 0;
  }

  .user-account__content-button_active {
    background: rgba(67, 32, 89, 0.37);
  }
}

.user-account__content-button img {
  margin-right: 5px;
  cursor: pointer;
}