.custom-soft-team {
  background: url("../../../../../public/images/team-background.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  //   height: 630px;
  margin-top: 120px;
  z-index: 0;
  margin-bottom: 70px;

  @media screen and (max-width: 1250px) {
    background: none;
  }

  .container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1250px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 768px) {
      padding: 0px 25px;
    }

    .left {
      background: url("../../../../../public/images/task.png");
      background-repeat: no-repeat;
      height: 700px;
      width: 50%;

      @media screen and (max-width: 1250px) {
        background: url("../../../../../public/images/task-reverse.png");
        background-repeat: no-repeat;
        background-position: center;
        height: 525px;
        width: 720px;
      }

      @media screen and (max-width: 768px) {
        background: url("../../../../../public/images/task-reverse.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 240px;
        width: 327px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;

      @media screen and (max-width: 1250px) {
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        width: 100%;
      }

      h1 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 12px;

        @media screen and (max-width: 1250px) {
          font-size: 24px;
          line-height: 30px;
          text-align: center;
        }

        @media screen and (max-width: 768px) {
          font-size: 20px;
          line-height: 25px;
          text-align: center;
        }
      }

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        margin: 0;
        margin-bottom: 24px;
      }

      .team-work-btn {
        max-width: 332px;
        background: #ffffff;
        border-radius: 42px;
        padding: 12px 36px;
        text-align: center;

        @media screen and (max-width: 768px) {
          width: 100%;
          text-align: center;
        }

        .gradient-text {
          font-family: "Unbounded";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 109.5%;
          text-align: center;
          letter-spacing: 0.05em;
          background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%),
            linear-gradient(0deg, #ffffff, #ffffff);

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .team-work-btn:hover {
        transition: 0.5s;
        cursor: pointer;
        background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);

        span {
          transition: 0.5s;
          background: #ffffff;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
      
    }
  }
}
