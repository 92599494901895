.content-screen {
  min-height: 400px;
  background-color: #391C50;
  border-radius: 0 0 15px 15px;
  padding: 20px;
  display: flex;
  margin-bottom: 25px;
  flex-direction: row;
}

.user-account {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.user-account__top-panel {
  display: flex;
  justify-content: space-between;
  min-width: 350px;
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.user-account__top-panel-left {
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.user-account__balance {
  background-color: #432059;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  color: #999;
  font-size: 14px;
  height: 55px;
  margin-right: 20px;

  @media screen and (max-width: 900px) {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.user-account__balance-number {
  font-size: 16px;
  color: #fff;
  margin-left: 5px;
  font-weight: bold;
  margin-right: 10px;
}

.user-account__balance-button {
  //background-color: red;
  color: #fff;
  height: 29px;
  line-height: 29px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  background: linear-gradient(121.85deg, #FF00E5 2.04%, #7000FF 108.41%);
  cursor: pointer;
}

.user-account__user-info {
  height: 55px;
  border-radius: 10px;
  background-color: #432059;
}

.user-account__user-info {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  color: #999;
}

.user-account__email {
  margin-left: 10px;
  color: #fff;
}

.user-account__logout-btn {
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.user-account__chat-button {
  background: linear-gradient(121.85deg, #FF00E5 2.04%, #7000FF 108.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //background-clip: text;
  //text-fill-color: transparent;
  font-size: 14px;
  border: 1px solid #EC01E8;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 12px;

  //font-family: 'Unbounded';
  font-style: normal;
  font-weight: 400;
  //font-size: 12px;
  line-height: 17px;
  cursor: pointer;

  width: 176px;
  height: 55px;

  border-radius: 10px;
  order: 1;
  flex-grow: 0;


  @media screen and (max-width: 900px) {
    margin-top: 10px;
    margin-left: 0;
  }
}

.user-account__content {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  height: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.user-account__left-content {
  margin-right: 20px;
  border-radius: 16px;
  padding: 16px;
  gap: 8px;
  width: 229px;
  background-color: #432059;
  height: fit-content;

  @media screen and (max-width: 1024px) {
    width: 100%;
    min-width: 350px;
    margin-right: 0;
  }
}

.user-account__left-content-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.user-account__app-title {
  //font-family: 'Unbounded';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 8px;
  gap: 6px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.user-account__app-title--active {
  background-color: #FF00E5;
}

.user-account__app-title:hover {
  background-color: #79409C;
}

.user-account__content-buttons {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 630px) {
    flex-direction: column;

    border-radius: 10px;
  }
}

.user-account__content-button {
  border-radius: 10px 10px 0 0;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  font-size: 14px;
  width: 100%;

  @media screen and (max-width: 1150px) {
    font-size: 10px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.user-account__right-content {
  min-width: 350px;
  width: 100%;
  height: 100%;
}

.user-account__content-button img {
  margin-right: 5px;
}

.user-account__content-button:hover {
  background: rgba(67, 32, 89, 0.37);
  cursor: pointer;
}

.user-account__content-button_active {
  background-color: #432059;
}


.loading-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
}

.loading-page__image {
  width: 200px;
}