.product-section {
    padding: 0px 20px;
    position: relative;
    margin-bottom: 150px;
  
    .container {
      min-height: 818px;
  
      @media screen and (max-width: 768px) {
        padding: 0 25px;
      }
  
      .gradient {
        width: 500px;
        height: 500px;
        position: fixed;
        background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
        filter: blur(347.5px);
        left: -100px;
        z-index: -1;
      }
  
      .product-section__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
  
        .title {
          font-family: "Unbounded";
          font-style: normal;
          font-weight: 700;
          font-size: 34px;
          line-height: 34px;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
  
        .title::before {
          content: url("../../../../../public/images/product-title-before.svg");
          margin-bottom: 28px;
        }
  
        .product-section__slider {
          display: flex;
          justify-content: space-between;
          padding-top: 90px;
          width: 100%;
          max-width: 800px;
          flex-wrap: wrap;
  
          @media screen and (max-width: 1200px) {
            max-width: 800px;
            justify-content: space-around;
          }
  
          @media screen and (max-width: 800px) {
            max-width: 400px;
            justify-content: center;
          }
  
          .product {
            display: flex;
            flex-direction: column;
            width: 360px;
  
            @media screen and (max-width: 1200px) {
              margin-bottom: 50px;
            }
  
            .product-top {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-bottom: 40px;
              margin-bottom: 12px;
              height: 80%;
  
              background: url(../../../../../public/images/product-top.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-position: center;
  
              .product-logo {
                width: 350px;
                height: 250px;
                margin-top: -50px;
                margin-bottom: 20px;
              }
  
              .product-top__info {
                padding: 0px 20px;
  
                h1 {
                  font-family: "Unbounded";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 22px;
                  line-height: 22px;
                  color: #ffffff;
                  margin-bottom: 10px;
                }
  
                span {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 109.5%;
                  letter-spacing: 0.05em;
                  color: #6c4b83;
                }
              }
            }
  
            .product-bottom {
              height: 88px;
              padding: 20px;
  
              display: flex;
              justify-content: space-between;
              background: url(../../../../../public/images/product-bottom.png);
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
  
              .product-explore__btn {
                padding: 15px;
                
                span{
                  font-family: "Unbounded";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 14px;
                  color: #ffffff;
                }
  
                background: linear-gradient(
                  121.85deg,
                  #ff00e5 2.04%,
                  #7000ff 108.41%
                );
                border-radius: 15px;
                display: flex;
                align-items: center;
              }
  
              .product-explore__btn:hover {
                transition: 0.5s;
                color: #ff00e5;
                background: #ffffff;
      
                span {
                  background: linear-gradient(
                    121.85deg,
                    #ff00e5 2.04%,
                    #7000ff 108.41%
                  );
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
  
              .price-container {
                display: flex;
                align-items: center;
  
                h1 {
                  font-family: "Unbounded";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 19px;
                  line-height: 19px;
                  color: #ffffff;
                  margin: 0;
                  margin-right: 5px;
                }
  
                span {
                  font-family: "Unbounded";
                  font-style: normal;
                  font-weight: 100;
                  font-size: 11px;
                  line-height: 11px;
                  color: #6c4b83;
                }
              }
            }
          }
        }
      }
    }
  }
  