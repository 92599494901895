.seller-about-section {
    background: url("../../../../../public/images/soft-background.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 630px;
    padding-top: 100px;
    margin-top: -50px;
    position: relative;
    z-index: 0;

    @media screen and (max-width: 1250px) {
        background: none;
        height: auto;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }


    .container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            padding: 0px 25px;
            flex-direction: column-reverse;
            align-items: center;
        }

        .left {
            width: 50%;
            background: url("../../../../../public/images/searcher.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: 400px;
            background-position: left;
            margin-left: -30px;

            @media screen and (max-width: 1250px) {
                flex: 0 0 400px;
            }

            @media screen and (max-width: 768px) {
                width: 90%;
                background-size: contain;
                background-position: center;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 475px;

            h1 {
                font-family: "Unbounded";
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 12px;

                @media screen and (max-width: 1250px) {
                    font-size: 24px;
                    line-height: 30px;
                }
            }

            p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #ffffff;
            }
        }
    }
}