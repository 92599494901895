.app-section {
    background: url("../../../../../public/images/soft-background-reverse.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: -200px;
    margin-bottom: 100px;
    position: relative;

    @media screen and (max-width: 1250px) {
        background: none;
    }

    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }

    .gradient {
        position: absolute;
        width: 506px;
        height: 506px;
        background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
        filter: blur(347.5px);
        left: 140px;
        top: 10px;
        z-index: 0;

        @media screen and (max-width: 1250px) {
            display: none;
        }
    }

    .container {
        height: 780px;
        width: 100%;
        padding-top: 220px;
        position: relative;
        z-index: 2;

        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (max-width: 1250px) {
            height: auto;
            align-items: center;
        }

        @media screen and (max-width: 768px) {
            padding: 0px 25px;
        }

        h1 {
            font-family: "Unbounded";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            color: #ffffff;
            max-width: 360px;
            margin-bottom: 10px;

            @media screen and (max-width: 1250px) {
                width: 100%;
                max-width: max-content;
            }

            @media screen and (max-width: 768px) {
                margin-bottom: 20px;
            }

        }

        .list {
            display: flex;
            justify-content: space-between;
            width: 100%;

            @media screen and (max-width: 1250px) {
                flex-direction: column;
                align-items: center;
            }

            .item {
                background: url("../../../../../public/images/trademanager-app-background.png");
                background-repeat: no-repeat;
                width: 360px;
                height: 180px;
                padding: 0px 20px;
                display: flex;
                align-items: center;

                @media screen and (max-width: 1250px) {
                    width: 100%;
                    background: url("../../../../../public/images/trademanager-app-background-long.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    max-width: 720px;
                    max-height: 143px;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;
                }

                @media screen and (max-width: 768px) {
                    background: url("../../../../../public/images/trademanager-app-background-small.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    max-width: 327px;
                    width: 100%;

                }

                h4 {
                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    margin: 0;

                    @media screen and (max-width: 1250px) {
                        text-align: center;
                    }

                    @media screen and (max-width: 1250px) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}