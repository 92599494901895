.attic {
    background-color: #260F38;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .separator {
        width: 100%;
        border: 1px solid #321846;
        margin: 30px 0px;
    }

    .container__attic {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        max-width: 1140px;
        padding: 0 65px;
        width: 100%;

        @media screen and (max-width: 890px) {
            flex-direction: column;
        }

        .links {
            display: flex;

            @media screen and (max-width: 890px) {
                flex-direction: column;
                align-items: center;
                width: 100%;
            }

            .link {
                font-family: "Unbounded";
                font-style: normal;
                font-weight: 100;
                font-size: 13px;
                line-height: 13px;
                color: #86689d;
                margin-left: 50px;
                white-space: nowrap;

                @media screen and (max-width: 890px) {
                    margin-bottom: 10px;
                    margin-left: 0px;
                }
            }
        }

        .text {
            font-family: "Unbounded";
            font-style: normal;
            font-weight: 100;
            font-size: 13px;
            line-height: 13px;
            color: #86689d;
            white-space: nowrap;

            @media screen and (max-width: 890px) {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
            }
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .header__logo {
            display: flex;
            align-items: center;
        }

        .header__navbar {
            display: flex;
            align-items: center;
            grid-gap: 20px;

            @media screen and (max-width: 890px) {
                display: none;
            }

            .navbar__link {
                cursor: pointer;
                font-family: "Unbounded";
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 15px;
                color: #84659c;
                padding: 13px 20px;
                white-space: nowrap;

                &:hover,
                &.active {
                    background: rgba(255, 255, 255, 0.11);
                    border-radius: 14px;
                }
            }
        }
    }
}