header {
    padding-top: 42px;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header__logo {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1050px) {
                position: relative;
                z-index: 4;
                order: 1;
            }
        }

        .header__mobile-menu {
            display: none;

            @media screen and (max-width: 1050px) {
                transform: translateX(-100%);
                transition: 0.3s all;
                z-index: 2;
                position: fixed;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.96);
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 150px;
                width: 100vw;
                height: 100%;

               .header {
                    position: fixed;
                    z-index: 5;
                    width: 100%;
                }

                .header-menu__links {
                    cursor: pointer;

                    width: 100vw;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    grid-gap: 44px;

                    .navbar__link {
                        cursor: pointer;
                        font-family: "Unbounded";
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 20px;
                        color: #ffffff;
                    }
                }
            }
        }

        .header__mobile-menu.active {
            transform: translateX(0%);
            transition: 0.3s all;
        }

        .header__mobile-btn {
            display: none;

            @media screen and (max-width: 1050px) {
                &.active {
                    &::after {
                        width: 20%;
                        transition: width 0.3s all;
                    }

                    span {
                        width: 60%;
                        transition: width 0.3s all;
                    }
                }

                z-index: 4;
                order: 3;
                width: 30px;
                height: 20px;
                margin-left: 30px;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                position: relative;
                flex-direction: column;

                span {
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    transition: width 0.3s all;
                }

                &::after,
                &::before {
                    transition: width 0.3s all;
                    content: "";
                    width: 100%;
                    height: 2px;
                    background: #fff;
                }
            }
        }

        .header__navbar {
            display: flex;
            align-items: center;
            grid-gap: 20px;

            @media screen and (max-width: 1050px) {
                display: none;
            }

            .navbar__link {
                font-family: "Unbounded";
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 15px;
                color: #ffffff;
                padding: 13px 20px;
                white-space: nowrap;

                &:hover,
                &.active {
                    background: rgba(255, 255, 255, 0.11);
                    border-radius: 14px;
                }
            }
        }

        .header__actions {
            display: flex;
            align-items: center;
            grid-gap: 12px;
            justify-content: flex-end;

            @media screen and (max-width: 652px) {
                display: none;
            }

            @media screen and (max-width: 1050px) {
                position: relative;
                z-index: 0;
                order: 2;
            }

            .actions__link-btn {
                color: #fff;
                background: rgba(255, 255, 255, 0.11);
                border-radius: 14px;
                height: 40px;
                display: flex;
                align-items: center;
                padding: 13px;
            }

            .navbar__link {
                cursor: pointer;
                font-family: "Unbounded";
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: #ffffff;
                height: 41px;
                padding-left: 20px;
                padding-right: 20px;

                &:hover,
                &.active {
                    background: rgba(255, 255, 255, 0.11);
                    border-radius: 14px;
                }
            }
        }
    }
}