.help-item {
    max-width: 880px;
    width: 100%;
    margin-bottom: 15px;
  
  
    .header {
      background: #331943;
      border-radius: 19px;
      padding: 22px 20px 22px 30px;
      text-align: start;
  
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h3 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        text-transform: capitalize;
        color: #ffffff;
        margin: 0;
      }
    }
  
    .content{
      display: none;
    }
  
    .header:hover {
      cursor: pointer;
    }
  }
  
  .help-item-open {
    max-width: 880px;
    width: 100%;
    margin-bottom: 15px;
  
    background: #2e163e;
    border-radius: 19px;
  
    .header {
      background: #331943;
      border-radius: 19px;
      padding: 22px 20px 22px 30px;
      text-align: start;
  
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h3 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        text-transform: capitalize;
        color: #ffffff;
        margin: 0;
  
      }
    }
  
    .content {
      
      padding: 22px 20px 22px 30px;
      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 109.5%;
        letter-spacing: 0.05em;
        color: #6c4b83;
        
      }
    }
  
    .header:hover {
      cursor: pointer;
    }
  }
  