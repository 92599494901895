.gallery {
    margin-top: 100px;
    margin-bottom: 100px;

    .container {

        @media screen and (max-width: 768px) {
            padding: 0 25px;
        }

        display: flex;
        flex-direction: column;

        .navigation-section {
            align-items: center;
            justify-content: space-between;
            max-width: 100%;
            min-height: auto;
            width: 100%;
            display: flex;
            margin-bottom: 40px;

            .text {
                font-family: "Unbounded";
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 28px;
                color: #ffffff;

                @media screen and (max-width: 652px) {
                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 22px;
                    color: #ffffff;
                }
            }

            .sliders {
                display: flex;
                align-items: center;
                justify-content: center;
                grid-gap: 20px;
                margin-left: 10px;

                .btn-left {
                    width: 41px;
                    height: 41px;
                    background: url("../../../public/images/slider-left.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                }

                .btn-left:hover {
                    background: url("../../../public/images/slider-active-left.svg");
                    cursor: pointer;
                }

                .btn-right {
                    width: 41px;
                    height: 41px;
                    background: url("../../../public/images/slider-right.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                }

                .btn-right:hover {
                    background: url("../../../public/images/slider-active-right.svg");
                    cursor: pointer;
                }
            }
        }

        .wrapper {
            height: auto;
            display: flex;
            justify-content: center;
            height: auto;

            .picture {
                width: 100%;
                height: auto;
            }
        }

    }
}