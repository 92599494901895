.custom-soft-created-project {
  margin-bottom: 50px;

  .container {
    text-align: center;

    @media screen and (max-width: 768px) {
      padding: 0px 25px;
    }

    .info {
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      min-height: auto;
      width: 100%;
      display: flex;
      margin-bottom: 40px;

      h1 {
        font-family: "Unbounded";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        color: #ffffff;

        @media screen and (max-width: 1250px) {
          font-size: 24px;
          line-height: 30px;
        }

        @media screen and (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .sliders {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 20px;

        .btn-left {
          width: 41px;
          height: 41px;
          background: url("../../../../../public/images/slider-left.svg");
          background-position: center;
          background-repeat: no-repeat;
        }

        .btn-left:hover {
          background: url("../../../../../public/images/slider-active-left.svg");
          cursor: pointer;
        }

        .btn-right {
          width: 41px;
          height: 41px;
          background: url("../../../../../public/images/slider-right.svg");
          background-position: center;
          background-repeat: no-repeat;
        }

        .btn-right:hover {
          background: url("../../../../../public/images/slider-active-right.svg");
          cursor: pointer;
        }
      }
    }



    .slider {
      margin-bottom: 60px;
      .custom-item {
        background: #2b143d;
        border-radius: 12px;
        background-image: url("../../../../../public/images/created-project-1.png");
        background-repeat: no-repeat;
        background-size: contain;
        height: 350px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        // width: 500px;
        margin: 0px 15px 0px 15px;

        @media screen and (max-width: 768px) {
          background-position: center;
          background-size: cover;
        }

        .mini-title {
          background: rgb(255, 0, 229);
          background: linear-gradient(152deg, rgba(255, 0, 229, 1) 0%, rgba(180, 1, 243, 1) 48%, rgba(112, 0, 255, 1) 100%);
          border-radius: 20px;
          line-height: 24px;
          padding-left: 20px;
          padding-right: 20px;
        }

        .title {
          background: #432059;
          border-radius: 12px;
          display: flex;
          padding: 22px;
          display: flex;
          align-items: center;
          align-self: flex-start;
          max-width: 470px;

          @media screen and (max-width: 768px) {
            padding: 10px;
          }

          h1 {
            font-family: "Unbounded";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
            margin-left: 15px;

            @media screen and (max-width: 768px) {
              font-weight: 400;
              font-size: 15px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}

#created-project-item-1 {
  background-image: url(../../../../../public/images/created-project-1.png);
}
#created-project-item-2 {
  background-image: url(../../../../../public/images/created-project-2.png);
}
#created-project-item-3 {
  background-image: url(../../../../../public/images/created-project-3.png);
}
#created-project-item-4 {
  background-image: url(../../../../../public/images/created-project-4.png);
}
#created-project-item-5 {
  background-image: url(../../../../../public/images/created-project-5.png);
}
#created-project-item-6 {
  background-image: url(../../../../../public/images/created-project-6.png);
}
#created-project-item-7 {
  background-image: url(../../../../../public/images/created-project-7.png);
}