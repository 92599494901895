.seller-help-section {
    background: url("../../../../../public/images/soft-background.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 630px;
    margin-top: -250px;
    padding-top: 150px;

    @media screen and (max-width: 1250px) {
        background: none;
        margin-top: 0px;
        padding-top: 0px;
        height: auto;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 768px) {
        background: none;
    }

    .container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1250px) {
            grid-gap: 35px;
        }

        @media screen and (max-width: 768px) {
            padding: 0px 25px;
            flex-direction: column;
            align-items: center;
        }

        .right {
            width: 50%;
            background: url("../../../../../public/images/guider.png");
            background-repeat: no-repeat;
            background-size: contain;
            height: 386px;
            background-position: right;

            @media screen and (max-width: 1250px) {
                flex: 0 0 295px;
                height: 295px;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                background-size: contain;
                background-position: center;
            }
        }

        .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 579px;

            h1 {
                font-family: "Unbounded";
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 40px;
                text-transform: uppercase;
                margin-bottom: 12px;

                @media screen and (max-width: 1250px) {
                    font-size: 24px;
                    line-height: 30px;
                }

                .gradient-text {
                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 40px;
                    text-align: center;
                    text-transform: uppercase;
                    background: linear-gradient(121.85deg,
                            #ff00e5 2.04%,
                            #7000ff 108.41%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;

                    @media screen and (max-width: 1250px) {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }
            }

            p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #ffffff;
            }
        }
    }
}