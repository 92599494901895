.links-section {
    margin-bottom: 100px;

    .container {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: 768px) {
            padding: 0px 25px;
        }

        .title {
            font-family: "Unbounded";
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 28px;
            color: #ffffff;
            margin-bottom: 40px;

            @media screen and (max-width: 1250px) {
                font-size: 22px;
                line-height: 22px;
                text-align: center;
            }

            @media screen and (max-width: 768px) {
                text-align: start;
            }
        }

        .links {
            display: flex;
            justify-content: space-between;
            width: 100%;

            @media screen and (max-width: 1250px) {
                max-width: 720px;
                margin-bottom: 25px;
            }

            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }

            .link {
                width: 410px;
                height: 215px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 50px 10px 25px;
                background: #321846;
                border-radius: 48px;

                @media screen and (max-width: 1250px) {
                    width: 342px;
                    height: 200px;
                }

                @media screen and (max-width: 768px) {
                    margin-bottom: 25px;
                }

                .logo {
                    width: 50px;
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .logo-container {
                    width: 160px;
                    height: 160px;
                    border-radius: 50px;
                    background-color: #2b143d;

                    @media screen and (max-width: 1250px) {
                        width: 120px;
                        height: 120px;
                    }
                }

                .right {
                    .title {
                        max-width: 150px;
                        font-family: "Unbounded";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 25.1525px;
                        line-height: 25px;
                        color: #ffffff;
                        text-align: left;

                        @media screen and (max-width: 1250px) {
                            font-family: "Unbounded";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 22px;

                            color: #ffffff;
                        }
                    }

                    .link__btn {
                        background: rgba(255, 255, 255, 0.11);
                        border-radius: 12.5763px;
                        padding: 10px 20px;

                        font-family: "Unbounded";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13.4746px;
                        line-height: 13px;
                        color: #ffffff;
                    }
                }
            }

            .link-gradient {
                width: 267px;
                height: 214px;
                background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
                border-radius: 55.6949px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 28px;
                    color: #ffffff;
                }

                @media screen and (max-width: 1250px) {
                    display: none;
                }
            }

            .link-gradient:hover {
                transition: 0.5s;
                color: #ff00e5;
                background: #ffffff;
                cursor: pointer;

                span {
                    background: linear-gradient(121.85deg,
                            #ff00e5 2.04%,
                            #7000ff 108.41%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .link-gradient-long {
            display: none;

            @media screen and (max-width: 1250px) {
                width: 100%;
                max-width: 720px;
                height: 90px;
                background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
                border-radius: 55.6949px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-family: "Unbounded";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 30px;
                    color: #ffffff;
                }

                @media screen and (max-width: 768px) {
                    max-width: 342px;
                }
            }
        }

        .link-gradient-long:hover {
            transition: 0.5s;
            color: #ff00e5;
            background: #ffffff;
            cursor: pointer;

            span {
                background: linear-gradient(121.85deg, #ff00e5 2.04%, #7000ff 108.41%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}