.login-form {
    font-family: Unbounded;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    z-index: 999;
}

.login-form__wrapper {}

.login-form__input-container {
    margin-bottom: 10px;
}

.login-form__input-title {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.login-form__title {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
}

.login-form__forgot-password {
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #8130B5;
    cursor: pointer;
}

.login-form__forgot-password:hover {
    color: #694E7D;
}

.login-form__no-account {
    cursor: pointer;
    margin-top: 30px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-decoration-line: underline;
    color: #8130B5;
}

.login-form__no-account:hover {
    color: #694E7D;
}